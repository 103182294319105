import translationGerman from '../translations/de.json';
import translationEn from '../translations/en.json';
import {App, Translator, DataManager, Toast} from "cordova-sites/dist/client";

import "cordova-sites-user-management/dist/client/js/translationInit"
import "cordova-sites/dist/client/js/translationInit"

import {LoginSite, RegistrationSite, UserManager} from "cordova-sites-user-management/dist/client";

import {BaseDatabase} from "cordova-sites-database/dist/cordova-sites-database";
import {DeleteUserManagement1000000000000} from "cordova-sites-user-management/dist/shared//migrations/DeleteUserManagement";
import {SetupUserManagement1000000001000} from "cordova-sites-user-management/dist/shared/migrations/SetupUserManagement";
import {Setup1000000002000} from "../../shared/model/migration/shared/Setup";
import {SetupEasySync1000000000500} from "cordova-sites-easy-sync/dist/client";
import {MainMenuSite} from "./Sites/MainMenuSite";
import {ClientSetup1000000002001} from "./model/migration/ClientSetup";
import {EasySyncClientDb} from "cordova-sites-easy-sync/dist/client/EasySyncClientDb";
import {NavbarFragment} from "cordova-sites/dist/client/js/Context/Menu/NavbarFragment";
import {NativeStoragePromise} from "cordova-sites/dist/client/js/NativeStoragePromise";

import music from "../sound/brightAndBeautifull__.mp3";
import {SoundManager} from "cordova-sites/dist/client/js/Sound/SoundManager";

window["JSObject"] = Object;

//Disable Google Analytics for VideoJS
window["HELP_IMPROVE_VIDEOJS"] = false;

LoginSite.ADD_LOGIN_ACTION = false;
RegistrationSite.ADD_REGISTRATION_ACTION = false;

App.addInitialization(async () => {
    Translator.init({
        translations: {
            "de": translationGerman,
            "en": translationEn
        },
        fallbackLanguage: "de",
        // markTranslations: true,
        markUntranslatedTranslations: true,
    });

    //Setting Title
    NavbarFragment.title = "WordRotator";

    let soundManager = SoundManager.getInstance();
    soundManager.play(SoundManager.CHANNELS.MUSIC, {
        audio: music,
        loop: true,
        volume: 0.6,
        muted: (await NativeStoragePromise.getItem("play-music", "1") !== "1")
    }).catch(e => console.error(e));

    await UserManager.getInstance().getMe().catch(e => console.error(e));

    UserManager.getInstance().addLoginChangeCallback(async (isLoggedIn) => {
        if (isLoggedIn) {
            // await new SyncJob().sync([UserManager.syncParamFor(Definition), UserManager.syncParamFor(ExerciseProgress), UserManager.syncParamFor(WrongAnswer)]).catch(e => console.error(e));
        }
    });
});

DataManager._basePath = __HOST_ADDRESS__;
DataManager.onlineCallback = isOnline => {
    if (!isOnline){
        new Toast("not online!").show();
    }
};

Object.assign(BaseDatabase.CONNECTION_OPTIONS, {
    logging: ["error",],
    synchronize: false,
    migrationsRun: true,
    migrations: [
        DeleteUserManagement1000000000000,
        SetupEasySync1000000000500,
        SetupUserManagement1000000001000,
        Setup1000000002000,
        ClientSetup1000000002001,
    ]
});

let app = new App();
app.start(MainMenuSite).catch(e => console.error(e)).then(async () => {
    window["queryDb"] = async (sql) => {
        // SELECT name FROM my_db.sqlite_master WHERE type='table';


        // console.log("DB", EasySyncClientDb.getInstance());
        let res = await EasySyncClientDb.getInstance().rawQuery(sql);
        console.log(res);
        return res;
    }
});
app.ready(() => {
    console.log("initialisation over", new Date());

    if (device.platform === "browser"){
        // Check that service workers are supported
        if ('serviceWorker' in navigator) {
            // Use the window load event to keep the page load performant
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('service-worker.js');
            });
        }
    }

});
