import {ParentSegment} from "./ParentSegment";
import {ViewHelper} from "js-helper/dist/client/ViewHelper";

export class RowSegment extends ParentSegment{
    constructor(element) {
        super(element);
        this.rotatable = false;
    }

    applyRotations(rotations)
    {
        for (let i = 0, n = this.children.length; i < n; i++) {
            rotations = this.children[i].applyRotations(rotations);
        }
        return rotations;
    }

    getCurrentRotations(rotations){
        for (let i = 0, n = this.children.length; i < n; i++) {
            rotations = this.children[i].getCurrentRotations(rotations);
        }
        return rotations;
    }

    getCurrentLocked(locked) {
        for (let i = 0, n = this.children.length; i < n; i++) {
            locked = this.children[i].getCurrentLocked(locked);
        }
        return locked;
    }

    applyLocks(locks) {
        for (let i = 0, n = this.children.length; i < n; i++) {
            locks = this.children[i].applyLocks(locks);
        }
        return locks;
    }

    _updateElement() {
        const childContainer = this.element.querySelector(".child-container");
        ViewHelper.removeAllChildren(childContainer);

        this._updateRotationClass();

        const self = this;
        this.element.onclick = function (e) {
            self.rotate();
            e.stopPropagation();
        };

        for (let i = 0, n = this.children.length; i < n; i++) {
            this.children[i]._updateElement();
            childContainer.appendChild(this.children[i].getElement());
        }
    }
}