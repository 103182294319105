import {Helper} from "js-helper/dist/shared/Helper";

export class TemplateContainer{
    constructor(leafTemplate, parentTemplate, rowTemplate, triangleTemplate, columnTemplate){
        this.leafTemplate = leafTemplate;
        this.parentTemplate = parentTemplate;
        this.rowTemplate = rowTemplate;
        this.triangleTemplate = triangleTemplate;
        this.columnTemplate = columnTemplate;
    }

    copyLeafTemplate()
    {
        return this.leafTemplate.cloneNode(true);
    }

    copyParentTemplate()
    {
        return this.parentTemplate.cloneNode(true);
    }

    copyRowTemplate()
    {
        return this.rowTemplate.cloneNode(true);
    }

    copyTriangleTemplate()
    {
        return this.triangleTemplate.cloneNode(true);
    }

    copyColumnTemplate(){
        return this.columnTemplate.cloneNode(true);
    }
}