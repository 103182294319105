import {FourWordsLevel} from "./FourWordsLevel";

export class MainMenuLevel extends FourWordsLevel{

    constructor(templateContainer) {
        super(templateContainer, 4);
    }

    saveAsCurrentLevel() {
    }

    // checkHasWon(delayPromise) {
    // }
}