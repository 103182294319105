import {MigrationInterface, QueryRunner, Table} from "typeorm";
import {MigrationHelper} from "js-helper/dist/shared";
import {LevelPlayed} from "../LevelPlayed";

export class ClientSetup1000000002001 implements MigrationInterface {

    async up(queryRunner: QueryRunner): Promise<any> {
        await MigrationHelper.addTableFromModelClass(LevelPlayed, queryRunner);
    }

    down(queryRunner: QueryRunner): Promise<any> {
        return undefined;
    }
}