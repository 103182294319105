import {BaseModel} from "cordova-sites-database/dist/BaseModel";
import {BaseDatabase} from "cordova-sites-database/dist/cordova-sites-database";
import {LevelData} from "../../../shared/model/LevelData";
import {Helper} from "js-helper/dist/shared/Helper";
import {SelectQueryBuilder} from "typeorm";

export class LevelPlayed extends BaseModel {

    levelData: LevelData;
    played: boolean;

    static getColumnDefinitions() {
        let columns = super.getColumnDefinitions();
        columns["played"] = {type: BaseDatabase.TYPES.BOOLEAN, default: true};
        return columns;
    }

    static getRelationDefinitions() {
        let relations = super.getRelationDefinitions();
        relations["levelData"] = {
            target: LevelData.getSchemaName(),
            type: "one-to-one",
            joinColumn: true,
            // cascade: true,
        };
        return relations;
    }

    static async setPlayed(levelData, played?){
        played = Helper.nonNull(played, true);
        let levelPlayed = new LevelPlayed();
        levelPlayed.levelData = levelData;
        levelPlayed.played = played;

        return await levelPlayed.save();
    }

    static async getNextLevelData(renderer){
        let qb = <SelectQueryBuilder<LevelData>>await this._database.createQueryBuilder(LevelData);

        qb = qb.select("COUNT(*) as count, difficulty")
            .leftJoin(LevelPlayed, "levelPlayed", "levelPlayed.levelData = LevelData.id")
            .where("(deleted = 0 OR deleted = 'false')")
            .andWhere("renderer IN ("+renderer.join(",")+")")
            .andWhere("(levelPlayed.id IS NULL OR levelPlayed.played = 0)")
            .groupBy("difficulty")
            .orderBy("difficulty, LevelData.id");

        let countRes = await qb.getRawMany();

        let difficulty = null;
        let offset = null;

        if (!countRes.some(res => {
            if (res["count"] > 0){
                difficulty = res["difficulty"];
                offset = Math.floor(Math.random() * Math.floor(res["count"]));
                return true;
            }
            return false;
        })){
            return null;
        }

        qb = <SelectQueryBuilder<LevelData>>await this._database.createQueryBuilder(LevelData);
        qb = qb
            .leftJoin(LevelPlayed, "levelPlayed", "levelPlayed.levelData = LevelData.id")
            .where("(deleted = 0 OR deleted = 'false')")
            .andWhere("difficulty = "+difficulty)
            .andWhere("renderer IN ("+renderer.join(",")+")")
            .andWhere("(levelPlayed.id IS NULL OR levelPlayed.played = 0)")
            .orderBy("LevelData.id")
            .limit(1)
            .offset(offset);

        return await qb.getOne();
    }
}
BaseDatabase.addModel(LevelPlayed);
