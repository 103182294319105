
export class Segment{
    constructor(element){
        this.rotation = 0;
        this.element = element;
        this.parent = null;
    }

    getCurrentRotations(rotations){
        return rotations;
    }

    sameAs(otherSegment){
        return false;
    }

    setParent(parent)
    {
        this.parent = parent;
    }
    
    getLevel()
    {
        if (this.parent!==null)
        {
            return this.parent.getLevel();
        }
    }

    canRotate(){
        return false;
    }

    isSolved(){
        return (this.rotation === 0);
    }
    
    async rotate(){
        return Promise.resolve();
    };

    _updateElement(){};

    applyRotations(rotations){
        return rotations;
    }

    applyLocks(locks)
    {
        return locks;
    }

    getCurrentLocked(lockedArray){
        return lockedArray;
    }

    getElement()
    {
        return this.element;
    }
}
