const view = require("../../html/sites/credits.html");
import {App} from "cordova-sites/dist/client/js/App";
import {MenuSite} from "cordova-sites/dist/client";

export class CreditsSite extends MenuSite{
    constructor(siteManager) {
        super(siteManager, view);
    }
}

App.addInitialization(app => {
    app.addDeepLink("credits", CreditsSite);
});
