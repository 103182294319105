const view = require("../../html/sites/privacyPolicy.html");
import {App} from "cordova-sites/dist/client/js/App";
import {Matomo} from "cordova-sites/dist/client/js/Legacy/Matomo";
import {MenuSite} from "cordova-sites/dist/client";

export class PrivacyPolicySite extends MenuSite {
    constructor(siteManager) {
        super(siteManager,view);
    }

    async onViewLoaded() {
        let trackSwitch =this.findBy("#track-switch");

        trackSwitch.addEventListener("change", function (e) {
            Matomo.setTrack(this.checked === true);
            e.stopPropagation();
            e.preventDefault();
        });
        return super.onViewLoaded();
    }

    async onStart(args) {
        let trackSwitch =this.findBy("#track-switch");
        trackSwitch.checked = ((await Matomo.getTrackingPromise()) === "1");

        // Matomo.update("Privacy Policy Sites");
        return super.onStart(args);
    }
}

App.addInitialization(app => {
    app.addDeepLink("privacyPolicy", PrivacyPolicySite);
});
