import {Level} from "./Level";
import {RowSegment} from "../Segment/RowSegment";
import {ParentSegment} from "../Segment/ParentSegment";
import {ColumnSegment} from "../Segment/ColumnSegment";

export class BigSegmentsLevels extends Level{
    constructor(templateContainer, wordLength, bigSegmentPositions) {
        super(templateContainer);
        this.wordLength = wordLength;
        this.bigSegmentPositions = bigSegmentPositions;
    }

    createSegments() {
        if (this.words.length >= 4 &&
            this.words[0].length >= this.wordLength &&
            this.words[1].length >= this.wordLength &&
            this.words[2].length >= this.wordLength &&
            this.words[3].length >= this.wordLength &&
            this.wordLength >= 4
        ) {
            let leafsWords = [];
            leafsWords[0] = Level._createLeafsForWord(this.words[0], this.templateContainer.copyLeafTemplate());
            leafsWords[1] = Level._createLeafsForWord(this.words[1], this.templateContainer.copyLeafTemplate());
            leafsWords[2] = Level._createLeafsForWord(this.words[2], this.templateContainer.copyLeafTemplate());
            leafsWords[3] = Level._createLeafsForWord(this.words[3], this.templateContainer.copyLeafTemplate());

            let rootSegment = new RowSegment(this.templateContainer.copyRowTemplate());
            for (let i = 0; i < this.wordLength / 2; i++) {
                let parents = [];
                parents[0] = new ParentSegment(this.templateContainer.copyParentTemplate());
                parents[1] = new ParentSegment(this.templateContainer.copyParentTemplate());

                parents[0].addChild(leafsWords[0][2 * i]);
                parents[0].addChild(leafsWords[0][2 * i + 1]);
                parents[0].addChild(leafsWords[1][2 * i]);
                parents[0].addChild(leafsWords[1][2 * i + 1]);

                parents[1].addChild(leafsWords[2][2 * i]);
                parents[1].addChild(leafsWords[2][2 * i + 1]);
                parents[1].addChild(leafsWords[3][2 * i]);
                parents[1].addChild(leafsWords[3][2 * i + 1]);

                let parentSegment =null;
                if (this.bigSegmentPositions.indexOf(i) !== -1){
                    parents[2] = new ParentSegment(this.templateContainer.copyParentTemplate());
                    parents[3] = new ParentSegment(this.templateContainer.copyParentTemplate());

                    parents[2].addChild(leafsWords[0][2 * i + 2]);
                    parents[2].addChild(leafsWords[0][2 * i + 3]);
                    parents[2].addChild(leafsWords[1][2 * i + 2]);
                    parents[2].addChild(leafsWords[1][2 * i + 3]);

                    parents[3].addChild(leafsWords[2][2 * i + 2]);
                    parents[3].addChild(leafsWords[2][2 * i + 3]);
                    parents[3].addChild(leafsWords[3][2 * i + 2]);
                    parents[3].addChild(leafsWords[3][2 * i + 3]);

                    parentSegment = new ParentSegment(this.templateContainer.copyParentTemplate());
                    parentSegment.addChild(parents[0]);
                    parentSegment.addChild(parents[2]);
                    parentSegment.addChild(parents[1]);
                    parentSegment.addChild(parents[3]);
                    i++;
                }
                else{
                    parentSegment = new ColumnSegment(this.templateContainer.copyColumnTemplate());
                    parentSegment.addChild(parents[0]);
                    parentSegment.addChild(parents[1]);
                }

                rootSegment.addChild(parentSegment);
            }
            this.setRootSegment(rootSegment)
        }
    }
}