import {Matomo, MenuSite, NativeStoragePromise, ScaleHelper, Toast} from "cordova-sites/dist/client";
import {App} from "cordova-sites/dist/client/js/App";
import {LevelPlayed} from "../model/LevelPlayed";
import {Helper} from "js-helper/dist/shared/Helper";
import {LevelHelper} from "../wordrotator/Level/LevelHelper";
import {ViewHelper} from "js-helper/dist/client/ViewHelper";
import {LevelData} from "../../../shared/model/LevelData";
import {TemplateContainer} from "../wordrotator/Segment/TemplateContainer";
import {LevelSite} from "./LevelSite";
import {SyncJob} from "cordova-sites-easy-sync/dist/client";
import {Level} from "../wordrotator/Level/Level";

const view = require("../../html/sites/tutorialSite.html");

export class TutorialSite extends MenuSite {
    private templateContainer: TemplateContainer;

    constructor(siteManager: any) {
        super(siteManager, view);
    }

    async onConstruct(constructParameters: any): Promise<any[]> {
        const res = super.onConstruct(constructParameters);
        await Promise.race([new SyncJob().sync([LevelData]).catch(e => console.error(e)), new Promise<void>(async resolve => {
            let levelData = await LevelData.findById(LevelSite.TUTORIAL.FIRST_LEVEL);
            if (levelData !== null) {
                resolve();
            }
        })]);
        return res;
    }

    async onViewLoaded(): Promise<any[]> {
        const res = super.onViewLoaded();

        let leafSegmentTemplate = this.findBy("#segment-leaf-template");
        let parentSegmentTemplate = this.findBy("#segment-parent-template");
        let rowSegmentTemplate = this.findBy("#segment-row-template");
        let triangleTemplate = this.findBy("#segment-triangle-template");
        let columnTemplate = this.findBy("#segment-column-template");

        leafSegmentTemplate.removeAttribute("id");
        parentSegmentTemplate.removeAttribute("id");
        rowSegmentTemplate.removeAttribute("id");
        triangleTemplate.removeAttribute("id");
        columnTemplate.removeAttribute("id");

        leafSegmentTemplate.remove();
        parentSegmentTemplate.remove();
        rowSegmentTemplate.remove();
        triangleTemplate.remove();
        columnTemplate.remove();

        this.templateContainer = new TemplateContainer(leafSegmentTemplate, parentSegmentTemplate, rowSegmentTemplate, triangleTemplate, columnTemplate);

        const tutorial1Container = this.findBy("#tutorial-1 .level-container");
        const tutorial2Container = this.findBy("#tutorial-2 .level-container");
        const tutorial3Container = this.findBy("#tutorial-3 .level-container");

        const level1 = await this.loadLevel(LevelSite.TUTORIAL.FIRST_LEVEL);
        const level2 = await this.loadLevel(LevelSite.TUTORIAL.SECOND_LEVEL);
        const level3 = await this.loadLevel(LevelSite.TUTORIAL.BIG_SEGMENT_LEVEL);

        level1.setSaveAsCurrentAfterCheckWon(false);
        level2.setSaveAsCurrentAfterCheckWon(false);
        level3.setSaveAsCurrentAfterCheckWon(false);

        tutorial1Container.appendChild(level1.getRootSegment().getElement());
        tutorial2Container.appendChild(level2.getRootSegment().getElement());
        tutorial3Container.appendChild(level3.getRootSegment().getElement());

        let pointer = this.findBy("#tutorial-pointer");
        pointer.remove();

        let rotatableSegments = level3.getRotatableSegments();
        let pointerSegment = rotatableSegments[2];
        pointerSegment.element.appendChild(pointer);

        this.find(".help-action").addEventListener("click", () => {
            TutorialSite.help(level2);
        })

        return res;
    }

    static async help(level: Level) {
        let rotatables = level.getRotatableSegments();
        rotatables = rotatables.filter((segment) => {
            return (!segment.isSolved(false));
        });

        if (rotatables.length === 0){
            return;
        }

        let index = Math.floor(Math.random() * rotatables.length);

        let segmentToHelp = rotatables[index];
        while (segmentToHelp.rotation !== 0) {
            segmentToHelp.rotate();
        }
        segmentToHelp.setIsRotatable(false);
    }

    async loadLevel(id) {
        try {
            let levelData = await LevelData.findById(id);
            const level = LevelHelper.inflateLevel(levelData, this.templateContainer);

            level.getWonPromise().then(() => {
                console.log("level won!");
                new Toast("you solved the tutorial level!").show();
                //TODO
                // this.levelWon(level);
            });

            level.createSegments();
            level.getRootSegment()._updateElement();

            return level;
        } catch (e) {
            console.log("Fehler!");
            console.error(e);
            // this.startEndSite();
            return null;
        }
    }
}

App.addInitialization(app => {
    app.addDeepLink("tutorial", TutorialSite);
})
