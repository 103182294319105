import {SimpleLevel} from "./SimpleLevel";
import {RowLevel8} from "./RowLevel8";
import {RowLevel10} from "./RowLevel10";
import {SixWordsRowLevel8} from "./SixWordsRowLevel8";
import {SixWordsRowLevel12} from "./SixWordsRowLevel12";
import {FourWordsLevel8} from "./FourWordsLevel8";
import {FourWordsLevel12} from "./FourWordsLevel12";
import {SimpleFourWordsLevel10_0} from "./SimpleFourWordsLevel10_0";
import {SimpleFourWordsLevel10_1} from "./SimpleFourWordsLevel10_1";
import {SimpleFourWordsLevel10_2} from "./SimpleFourWordsLevel10_2";
import {SimpleFourWordsLevel10_3} from "./SimpleFourWordsLevel10_3";
import {TwoSegmentFourWordsLevel10_0_3} from "./TwoSegmentFourWordsLevel10_0_3";
import {TwoSegmentFourWordsLevel10_1_3} from "./TwoSegmentFourWordsLevel10_1_3";
import {TwoSegmentFourWordsLevel10_0_2} from "./TwoSegmentFourWordsLevel10_0_2";
import {Level} from "./Level";

export class LevelHelper {
    public static readonly types = {
        20: SimpleLevel,
        40: RowLevel8,
        60: RowLevel10,
        80: SimpleFourWordsLevel10_0,
        81: SimpleFourWordsLevel10_1,
        82: SimpleFourWordsLevel10_2,
        83: SimpleFourWordsLevel10_3,
        100: SixWordsRowLevel8,
        110: TwoSegmentFourWordsLevel10_0_3,
        111: TwoSegmentFourWordsLevel10_1_3,
        112: TwoSegmentFourWordsLevel10_0_2,
        120: FourWordsLevel8,
        140: SixWordsRowLevel12,
        160: FourWordsLevel12,
    };
    static setLevelType(typeId, level) {
        LevelHelper.types[typeId] = level;
    }

    static getLevelClass(type) {
        return LevelHelper.types[type];
    }

    static inflateLevel(levelData, templateContainer): Level {
        let level = new (LevelHelper.types[levelData["renderer"]])(templateContainer);
        level.setWords(JSON.parse(levelData["words"].replace(/&quot;/g, '"')));
        level.setId(levelData["id"]);
        level.setLevelData(levelData);

        levelData["positions"] = JSON.parse(levelData["positions"]);
        for (let i = 0, n = levelData["positions"].length; i < n; i++) {
            if (levelData["positions"][i] <= 4) {
                levelData["positions"][i] = 90 * levelData["positions"][i];
            }
        }

        level.setStartRotations(levelData["positions"]);
        return level;
    }
}
