import {Level} from "./Level";
import {RowSegment} from "../Segment/RowSegment";
import {ParentSegment} from "../Segment/ParentSegment";

export class RowLevel extends Level {
    constructor(container, wordLength) {
        super(container);
        this.wordLength = wordLength;
    }

    createSegments() {
        if (this.words.length >= 2 && this.words[0].length >= this.wordLength && this.words[1].length >= this.wordLength) {
            let leafsWordOne = Level._createLeafsForWord(this.words[0], this.templateContainer.copyLeafTemplate());
            let leafsWordTwo = Level._createLeafsForWord(this.words[1], this.templateContainer.copyLeafTemplate());

            let rootSegment = new RowSegment(this.templateContainer.copyRowTemplate());
            for (let i = 0, n = this.wordLength / 2; i < n; i++) {
                let parent = new ParentSegment(this.templateContainer.copyParentTemplate());
                parent.addChild(leafsWordOne[2 * i]);
                parent.addChild(leafsWordOne[2 * i + 1]);
                parent.addChild(leafsWordTwo[2 * i]);
                parent.addChild(leafsWordTwo[2 * i + 1]);
                rootSegment.addChild(parent);
            }
            // rootSegment.applyRotations(this.startRotations);
            this.setRootSegment(rootSegment)
        }
    }
}