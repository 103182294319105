const view = require("../../html/sites/settingsSite.html");
import {MenuSite} from "cordova-sites/dist/client/js/Context/MenuSite";
import {WordRotatorSettingFragment} from "../Fragment/WordRotatorSettingFragment";
import {App} from "cordova-sites/dist/client/js/App";

export class SettingsSite extends MenuSite {

    constructor(siteManager) {
        super(siteManager, view);
        this.addFragment("#wordRotatorSettings", new WordRotatorSettingFragment(this));
    }
}

App.addInitialization((app) => {
    app.addDeepLink("settings", SettingsSite);
});
