import {AccessEasySyncModel} from "cordova-sites-user-management/dist/shared";
import {BaseDatabase} from "cordova-sites-database/dist/cordova-sites-database";

export class LevelData extends AccessEasySyncModel{
    static getColumnDefinitions() {
        let columns = super.getColumnDefinitions();
        columns["words"] = BaseDatabase.TYPES.STRING;
        columns["positions"] = BaseDatabase.TYPES.STRING;
        columns["renderer"] = BaseDatabase.TYPES.INTEGER;
        columns["lang"] = BaseDatabase.TYPES.INTEGER;
        columns["difficulty"] = BaseDatabase.TYPES.INTEGER;
        return columns;
    }

    static async getNextLevelData(renderer){
        debugger;
    }
}
BaseDatabase.addModel(LevelData);