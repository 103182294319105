const view = require("../../html/sites/impressum.html");
import {App} from "cordova-sites/dist/client/js/App";
import {MenuSite} from "cordova-sites/dist/client";

export class ImpressumSite extends MenuSite{
    constructor(siteManager) {
        super(siteManager, view);
    }
}

App.addInitialization(app => {
    app.addDeepLink("impressum", ImpressumSite);
});
