import {LeafSegment} from "../Segment/LeafSegment";
import {ParentSegment} from "../Segment/ParentSegment";
import {Helper} from "js-helper/dist/shared/Helper";
import {NativeStoragePromise} from "cordova-sites/dist/client";
import {Random} from "js-helper";

export class Level {
    constructor(templateContainer) {
        this.rootSegment = null;
        this.words = [];
        this.startRotations = [];
        this.templateContainer = templateContainer;

        this.levelData = null;

        this.hasWon = false;
        this.id = null;

        this.saveAsCurrentAfterCheckWon = true;

        this.wonResolver = null;
        this.giveUpResolver = null;

        this._shakeLeafs = true;
        this._shakeLeafsTimeout = null;
        this._lastRotation = 0;

        this.wonPromise = new Promise((resolve, reject) => {
            this.wonResolver = resolve;
            this.giveUpResolver = reject;
        });

        this.segmentClickedListener = () => {
            // console.log("clicked")
        };
    }

    getLevelData() {
        return this.levelData;
    }

    setLevelData(levelData) {
        this.levelData = levelData;
    }

    async saveAsCurrentLevel() {
        let rotations = this.getCurrentRotations();
        let locked = this.getCurrentLocked();
        await NativeStoragePromise.setItem("currentLevel", JSON.stringify({
            "id": this.id,
            "rotations": rotations,
            "locks": locked
        }));
    }

    getCurrentLocked() {
        if (this.rootSegment !== null) {
            return this.rootSegment.getCurrentLocked([]);
        }
        return [];
    }

    getCurrentRotations() {
        if (this.rootSegment !== null) {
            return this.rootSegment.getCurrentRotations([]);
        }
        return [];
    }

    setLocks(locks) {
        if (this.rootSegment !== null) {
            this.rootSegment.applyLocks(locks);
        }
    }

    setId(id) {
        this.id = id;
    }

    getId() {
        return this.id;
    }

    getLevel() {
        return this;
    }

    setRootSegment(rootSegment) {
        this.rootSegment = rootSegment;
        this.rootSegment.setParent(this);
        if (this.startRotations) {
            this.applyRotations();
        }
    }

    setWords(words) {
        this.words = [];
        for (let i = 0, n = words.length; i < n; i++) {
            this.words.push(words[i].replace(/ß/g, "ẞ").toUpperCase());
        }
    }

    setStartRotations(rotations) {
        this.startRotations = rotations;
    }

    applyRotations(rotations) {
        if (this.rootSegment) {
            rotations = Helper.nonNull(rotations, this.startRotations);
            this.rootSegment.applyRotations(rotations);
        }
    }

    getHasWon() {
        return this.hasWon;
    }

    checkHasWon(delayPromise) {
        if (this.rootSegment.isSolved()) {
            this.hasWon = true;
            const self = this;
            Promise.resolve(delayPromise).then(() => {
                self.wonResolver(true);
            });
            return true;
        }
        if (this.saveAsCurrentAfterCheckWon) {
            this.saveAsCurrentLevel();
        }
        return false;
    }

    setSaveAsCurrentAfterCheckWon(shouldSave){
        this.saveAsCurrentAfterCheckWon = (shouldSave === true);
    }

    getWonPromise() {
        return this.wonPromise;
    }

    getRootSegment() {
        return this.rootSegment;
    }

    createSegments() {
    };

    getRotatableSegments() {
        return Level._getRotatableSegmentsFrom(this.rootSegment);
    }

    segmentClicked(segment) {
        if (this.segmentClickedListener) {
            this.segmentClickedListener(segment);
        }

        this._lastRotation = new Date().getTime();
        clearTimeout(this._shakeLeafsTimeout);
        if (this._shakeLeafs) {
            this._shakeLeafsTimeout = setTimeout(() => {
                this.shakeLeafs();
            }, Random.getIntRandom(5000) + 25000);
        }
    }

    setSegmentClickedListener(listener) {
        this.segmentClickedListener = listener;
    }

    getLeafSegments() {
        return Level._getLeafSegmentsFrom(this.rootSegment);
    }

    static _getRotatableSegmentsFrom(segment) {
        let rotatable = [];
        if (segment.canRotate()) {
            rotatable.push(segment);
        }
        if (segment instanceof ParentSegment) {
            for (let i = 0; i < segment.children.length; i++) {
                rotatable.push.apply(rotatable, Level._getRotatableSegmentsFrom(segment.children[i]));
            }
        }
        return rotatable;
    }

    shakeLeafs() {
        clearTimeout(this._shakeLeafsTimeout);
        if (this._shakeLeafs && !this.hasWon) {

            const leafs = this.getLeafSegments();
            if (leafs.length > 0) {
                const index = Random.getIntRandom(leafs.length - 1);
                const leaf = leafs[index];

                leaf.getElement().classList.add("shake");
                setTimeout(() => {
                    leaf.getElement().classList.remove("shake");
                }, Random.getIntRandom(350) + 650);
            }
            this._shakeLeafsTimeout = setTimeout(() => {
                this.shakeLeafs();
            }, Random.getIntRandom(2000) + 5000);
        }
    }

    static _createLeafsForWord(word, leafSegmentTemplate) {
        let leafSegments = [];
        for (let i = 0, n = word.length; i < n; i++) {
            leafSegments.push(new LeafSegment(leafSegmentTemplate.cloneNode(true), word.charAt(i)));
        }
        return leafSegments;
    }

    static _getLeafSegmentsFrom(segment) {
        let leafs = [];
        if (segment instanceof LeafSegment) {
            leafs.push(segment);
        } else if (segment instanceof ParentSegment) {
            for (let i = 0; i < segment.children.length; i++) {
                leafs.push(...Level._getLeafSegmentsFrom(segment.children[i]));
            }
        }
        return leafs;
    }
}
