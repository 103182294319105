import {Segment} from "./Segment";
import {Helper} from "js-helper/dist/shared/Helper";
import {ViewHelper} from "js-helper/dist/client/ViewHelper";

export class LeafSegment extends Segment {

    constructor(element, leaf) {
        super(element);
        this.leaf = 'A';
        if (Helper.isNotNull(leaf)) {
            this.setLeaf(leaf);
        }
    }

    sameAs(otherSegment) {
        // debugger;
        return (otherSegment instanceof LeafSegment && otherSegment.leaf === this.leaf);
    }

    setLeaf(leaf) {
        this.leaf = leaf;
    }

    _updateElement() {
        ViewHelper.removeAllChildren(this.element.querySelector(".leaf-element")).appendChild(document.createTextNode(this.leaf));
    }
}