const settingsIcon = require("../../img/settings.png").default;
const view = require("../../html/sites/menu.html");

import {App, Toast, DataManager, ScaleHelper, MenuSite} from "cordova-sites/dist/client";
import {Helper} from "js-helper/dist/shared";
import {LevelSite} from "./LevelSite";
import {TemplateContainer} from "../wordrotator/Segment/TemplateContainer";
import {MainMenuLevel} from "../wordrotator/Level/MainMenuLevel";
import {NativeStoragePromise} from "cordova-sites/dist/client";
import {ViewHelper} from "js-helper/dist/client/ViewHelper";
import {LevelPlayed} from "../model/LevelPlayed";
import {SyncJob} from "cordova-sites-easy-sync/dist/client/SyncJob";
import {LevelData} from "../../../shared/model/LevelData";
import {ShareManager} from "cordova-sites/dist/client/js/ShareManager/ShareManager";
import {Translator} from "cordova-sites/dist/client/js/Translator";
import {SoundManager} from "cordova-sites/dist/client/js/Sound/SoundManager";
import {Matomo} from "cordova-sites/dist/client/js/Legacy/Matomo";
import {MenuAction} from "cordova-sites/dist/client/js/Context/Menu/MenuAction/MenuAction";
import {SettingsSite} from "./SettingsSite";

export class MainMenuSite extends MenuSite {
    static app: App;

    private loadLevelPromise: Promise<any>;
    private listener: () => void;
    private templateContainer: TemplateContainer;
    private randomRotateTimeout;

    constructor(siteManager) {
        super(siteManager, view);
        this.loadLevelPromise = new SyncJob().sync([LevelData]).catch(e => console.error(e));
        this.listener = null;
        this.getNavbarFragment().setCanGoBack(false);
    }

    onCreateMenu(navbar) {
        super.onCreateMenu(navbar);
        let settingsAction = new MenuAction("", () => {
            this.startSite(SettingsSite);
        }, MenuAction.SHOW_ALWAYS, null, settingsIcon);
        navbar.addAction(settingsAction);
        return navbar;
    }

    async onViewLoaded() {
        const res = super.onViewLoaded();

        let playButton = this.findBy("#play-button");
        playButton.addEventListener("click", () => {
            // Matomo.push(["trackEvent", "MainMenu", "startButton"]);
            this.startLevelSite();
        });

        let leafSegmentTemplate = this.findBy("#segment-leaf-template");
        let parentSegmentTemplate = this.findBy("#segment-parent-template");
        let rowSegmentTemplate = this.findBy("#segment-row-template");
        let triangleTemplate = this.findBy("#segment-triangle-template");

        leafSegmentTemplate.id = null;
        parentSegmentTemplate.id = null;
        rowSegmentTemplate.id = null;
        triangleTemplate.id = null;

        leafSegmentTemplate.remove();
        parentSegmentTemplate.remove();
        rowSegmentTemplate.remove();
        triangleTemplate.remove();

        this.templateContainer = new TemplateContainer(leafSegmentTemplate, parentSegmentTemplate, rowSegmentTemplate, triangleTemplate);

        //TODO?
        // if (Helper.nonNull(MainMenuSite.app._cookieClosePromise)) {
        //     MainMenuSite.app._cookieClosePromise.then(() => {
        //         if (this.listener) {
        //             this.listener();
        //         }
        //     });
        // }

        let soundManager = SoundManager.getInstance();

        let playMusicButton = this.findBy("#play-music");
        playMusicButton.checked = (await NativeStoragePromise.getItem("play-music", "1") === "1");
        playMusicButton.addEventListener("change", async () => {
            await NativeStoragePromise.setItem("play-music", (playMusicButton.checked) ? "1" : "0");
            soundManager.set({muted: !playMusicButton.checked}, SoundManager.CHANNELS.MUSIC);
            if (playMusicButton.checked) {
                await soundManager.play(SoundManager.CHANNELS.MUSIC);
            }

            //TODO [MIG] Matomo
            Matomo.push(["trackEvent", "MainMenu", "PlayMusic", "Play Music", (playMusicButton.checked) ? 1 : 0]);
        });

        let playSoundButton = this.findBy("#play-sound");
        playSoundButton.checked = (await NativeStoragePromise.getItem("play-sound", "1") === "1");
        playSoundButton.addEventListener("change", async () => {
            await NativeStoragePromise.setItem("play-sound", (playSoundButton.checked) ? "1" : "0");
            soundManager.set({muted: !playSoundButton.checked}, SoundManager.CHANNELS.SOUND);

            //TODO [MIG] Matomo
            Matomo.push(["trackEvent", "MainMenu", "PlaySound", "Play Sound", (playSoundButton.checked) ? 1 : 0]);
        });

        this.findBy("#share-button").addEventListener("click", () => {
            new ShareManager().share(Translator.getInstance().translate("share-text", [window.location]));
        });
        return res;
        // this.findBy("#share-buttons").appendChild(ShareManager.generateDefaultShareElement("https://wordrotator.silas.link"));
    }

    async onStart(args) {
        // Matomo.update("Menu Sites");
        let res = super.onStart(args);

        let level = new MainMenuLevel(this.templateContainer);
        level.setWords(["WORD", "ROTA", "TORW", "ORDR"]);
        level.createSegments();

        level.getWonPromise().then(() => {
            // Matomo.push(["trackEvent", "MainMenu", "levelSolved"]);
            this.startLevelSite();
        });

        let segment = level.getRootSegment();
        segment._updateElement();

        let levelSegment = this.findBy("#level");
        ViewHelper.removeAllChildren(levelSegment).appendChild(segment.getElement());

        let rotationsSegments = level.getRotatableSegments();

        let randomRotationFunction = () => {
            let timeout = Math.random() * 4500 + 1500;
            this.randomRotateTimeout = setTimeout(() => {
                let indexBlocked = -1;
                let indexesNotRight = [];
                for (let i = 0; i < rotationsSegments.length; i++) {
                    if (rotationsSegments[i].rotation !== 0) {
                        indexesNotRight.push(i);
                        if (indexesNotRight.length >= 2) {
                            break;
                        }
                    }
                }
                if (indexesNotRight.length === 1) {
                    indexBlocked = indexesNotRight[0];
                }

                let index = Math.floor(Math.random() * rotationsSegments.length);
                if (index === indexBlocked) {
                    index = (index + 1) % rotationsSegments.length;
                }

                rotationsSegments[index].rotate();
                randomRotationFunction();
            }, timeout);
        };
        randomRotationFunction();

        this.listener = async () => {
            let playButton = this.findBy("#play-button");
            let levelNumber = this.findBy("#level-number");
            levelNumber.innerText = Helper.nonNull(await NativeStoragePromise.getItem("levelCounter"), 1);

            let levelSegment = this.findBy("#level");

            let scaleHelper = new ScaleHelper();
            await scaleHelper.scaleToFull(levelSegment, levelSegment.parentElement, false, false, 2, 8, null, false);

            let levelStyle = getComputedStyle(levelSegment);
            playButton.style.width = levelStyle.getPropertyValue("width");
            scaleHelper.scaleTo(0.65, playButton.children[0], playButton, null, null, null, 4, null, false);

            await scaleHelper.scaleTo(0.1, levelNumber.parentElement, levelNumber.parentElement.parentElement, null, null, null, 10, null, false);
            scaleHelper.scaleTo(1, levelNumber, levelNumber.parentElement, false, false, 5, null, null, false);
        };

        // this.listener();
        window.addEventListener("resize", this.listener);
        setTimeout(this.listener, 50);

        //Musikbuttons update, falls in den Einstellungen umgestellt
        // let settingsManager = SettingsManager.getInstance();
        let playSoundButton = this.findBy("#play-sound");
        playSoundButton.checked = (await NativeStoragePromise.getItem("play-sound", "1") === "1");
        let playMusicButton = this.findBy("#play-music");
        playMusicButton.checked = (await NativeStoragePromise.getItem("play-music", "1") === "1");

        return res;
    }

    async startLevelSite() {
        SoundManager.getInstance().resumeContext();

        this.startSite(LevelSite, Promise.race([this.loadLevelPromise, new Promise<void>(async resolve => {
            let levelData = await LevelPlayed.getNextLevelData(LevelSite.RENDERER_TYPES);
            if (levelData !== null) {
                resolve();
            }
        })]));
    }

    onPause() {
        clearTimeout(this.randomRotateTimeout);
        window.removeEventListener("resize", this.listener);
        return super.onPause();
    }

    // async loadLevels() {
    //     try {
    //         const db = await WordRotatorDb.getInstance();
    //         const dateLastSync = Helper.nonNull(await db.loadDateLastSync(), 0);
    //         // const dateLastSync = Helper.nonNull(localStorage.getItem("date-last-sync"), 0);
    //
    //         // let numberLevels = db.countLevels();
    //         let newLastSync = null;
    //         let maxRuns = 1;
    //         let levelPromises = [];
    //         for (let run = 0; run < maxRuns; run++) {
    //             let res = await DataManager.load("wordRotator/levels" + DataManager.buildQuery({
    //                 "currentRun": run,
    //                 "dateLastSync": dateLastSync
    //             }));
    //             if (!res["success"]) {
    //                 if (await db.loadNextLevel(LevelSite.RENDERER_TYPES) === null) {
    //                     new Toast("sync-error", 6000).show();
    //                 }
    //                 newLastSync = null;
    //                 break;
    //             }
    //             res = res["result"];
    //             newLastSync = Helper.nonNull(newLastSync, res["currentSyncDate"]);
    //             maxRuns = res["maxRuns"];
    //
    //             let levels = res["levels"];
    //             for (let i = 0; i < levels.length; i++) {
    //                 let currentLevel = levels[i];
    //                 levelPromises.push(db.loadLevel(levels[i]["id"]).then(level => {
    //                     currentLevel["played"] = (Helper.nonNull(Helper.nonNull(level, {}).played, false));
    //                     return currentLevel;
    //                 }));
    //             }
    //         }
    //         let levels = await Promise.all(levelPromises);
    //         await db.saveManyLevels(levels);
    //
    //         if (newLastSync != null && newLastSync !== "null") {
    //             await NativeStoragePromise.setItem("date-last-sync", newLastSync);
    //             db.saveDateLastSync(newLastSync);
    //         }
    //     }
    //     catch(e){
    //         // if (await db.loadNextLevel(LevelSite.RENDERER_TYPES) === null) {
    //             new Toast("sync-error", 6000);
    //         // }
    //         console.error(e);
    //     }
    // }
}

App.addInitialization(app => {
    MainMenuSite.app = app;
});
