import {TutorialSite} from "../Sites/TutorialSite";

const view = require("../../html/fragment/settings.html");

import {CreditsSite} from "../Sites/CreditsSite";
import {PrivacyPolicySite} from "../Sites/PrivacyPolicySite";
import {ImpressumSite} from "../Sites/ImpressumSite";
import {NativeStoragePromise} from "cordova-sites/dist/client/js/NativeStoragePromise";
import {AbstractFragment} from "cordova-sites/dist/client/js/Context/AbstractFragment";
import {LevelData} from "../../../shared/model/LevelData";
import {LastSyncDates} from "cordova-sites-easy-sync/dist/client/LastSyncDates";
import {SoundManager} from "cordova-sites/dist/client/js/Sound/SoundManager";
import {Matomo} from "cordova-sites/dist/client/js/Legacy/Matomo";
import {LevelPlayed} from "../model/LevelPlayed";
import {ContactSite} from "../Sites/ContactSite";

export class WordRotatorSettingFragment extends AbstractFragment {
    constructor(site) {
        super(site, view);
    }

    async onViewLoaded() {
        let res = super.onViewLoaded();

        this.findBy("#reset-levels").addEventListener("click", async () => {
            await NativeStoragePromise.remove("currentLevel");
            await NativeStoragePromise.remove("levelCounter");
            await NativeStoragePromise.remove("tutorial-step");

            await LevelPlayed.clear();
            await LevelData.clear();
            await LastSyncDates.clear();
        });

        if (location.hostname.includes("beta") || location.hostname.includes("127.0.0.1") || location.hostname.includes("localhost")) {
            // this.findBy("#reset-levels").classList.remove("hidden");
        }

        let playMusicButton = this.findBy("#play-music");
        playMusicButton.checked = (await NativeStoragePromise.getItem("play-music", "1") === "1")
        playMusicButton.addEventListener("change", async () => {
            await NativeStoragePromise.setItem("play-music", (playMusicButton.checked) ? "1" : "0");
            let soundManager = SoundManager.getInstance();
            soundManager.set({muted: !playMusicButton.checked}, SoundManager.CHANNELS.MUSIC);
            if (playMusicButton.checked) {
                await soundManager.play(SoundManager.CHANNELS.MUSIC);
            }

            //TODO [MIG] Matomo
            Matomo.push(["trackEvent", "MainMenu", "PlayMusic", "Play Music", (playMusicButton.checked) ? 1 : 0]);
        });

        let playSoundButton = this.findBy("#play-sound");
        playSoundButton.checked = (await NativeStoragePromise.getItem("play-sound", "1") === "1");
        playSoundButton.addEventListener("change", async () => {
            await NativeStoragePromise.setItem("play-sound", (playSoundButton.checked) ? "1" : "0");
            let soundManager = SoundManager.getInstance();
            soundManager.set({muted: !playSoundButton.checked}, SoundManager.CHANNELS.SOUND);

            //TODO [MIG] Matomo
            Matomo.push(["trackEvent", "MainMenu", "PlaySound", "Play Sound", (playSoundButton.checked) ? 1 : 0]);
        });

        this.findBy("#track-switch").addEventListener("change", function (e) {
            Matomo.setTrack(this.checked === true);
            e.stopPropagation();
            e.preventDefault();
        });

        this.findBy("#tutorial-button").addEventListener("click", () => {
            this.getSite().startSite(TutorialSite);
        });
        this.findBy("#credits-button").addEventListener("click", () => {
            this.getSite().startSite(CreditsSite);
        });
        this.findBy("#privacy-policy-button").addEventListener("click", () => {
            this.getSite().startSite(PrivacyPolicySite);
        });
        this.findBy("#contact-button").addEventListener("click", () => {
            // new Toast("contact site").show();
            this.getSite().startSite(ContactSite);
        });
        this.findBy("#impressum-button").addEventListener("click", () => {
            this.getSite().startSite(ImpressumSite);
        });
        // @ts-ignore
        this.findBy("#version-info").innerText = __VERSION__;

        return res;
    }

    onStart(args) {
        Matomo.update("Settings Sites");
        return super.onStart(args);
    }
}
