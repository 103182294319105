import {Level} from "./Level";
import {RowSegment} from "../Segment/RowSegment";
import {ParentSegment} from "../Segment/ParentSegment";

export class FourWordsLevel extends Level {

    constructor(templateContainer, wordLength) {
        super(templateContainer);
        this.wordLength = wordLength;
    }

    createSegments() {
        if (this.words.length >= 4 &&
            this.words[0].length >= this.wordLength &&
            this.words[1].length >= this.wordLength &&
            this.words[2].length >= this.wordLength &&
            this.words[3].length >= this.wordLength
        ) {
            let leafsWords = [];
            leafsWords[0] = Level._createLeafsForWord(this.words[0], this.templateContainer.copyLeafTemplate());
            leafsWords[1] = Level._createLeafsForWord(this.words[1], this.templateContainer.copyLeafTemplate());
            leafsWords[2] = Level._createLeafsForWord(this.words[2], this.templateContainer.copyLeafTemplate());
            leafsWords[3] = Level._createLeafsForWord(this.words[3], this.templateContainer.copyLeafTemplate());

            let rootSegment = new RowSegment(this.templateContainer.copyRowTemplate());
            for (let i = 0; i < this.wordLength / 4; i++) {

                let parents = [];
                parents[0] = new ParentSegment(this.templateContainer.copyParentTemplate());
                parents[1] = new ParentSegment(this.templateContainer.copyParentTemplate());
                parents[2] = new ParentSegment(this.templateContainer.copyParentTemplate());
                parents[3] = new ParentSegment(this.templateContainer.copyParentTemplate());

                parents[0].addChild(leafsWords[0][4 * i]);
                parents[0].addChild(leafsWords[0][4 * i + 1]);
                parents[0].addChild(leafsWords[1][4 * i]);
                parents[0].addChild(leafsWords[1][4 * i + 1]);

                parents[1].addChild(leafsWords[0][4 * i + 2]);
                parents[1].addChild(leafsWords[0][4 * i + 3]);
                parents[1].addChild(leafsWords[1][4 * i + 2]);
                parents[1].addChild(leafsWords[1][4 * i + 3]);

                parents[2].addChild(leafsWords[2][4 * i]);
                parents[2].addChild(leafsWords[2][4 * i + 1]);
                parents[2].addChild(leafsWords[3][4 * i]);
                parents[2].addChild(leafsWords[3][4 * i + 1]);

                parents[3].addChild(leafsWords[2][4 * i + 2]);
                parents[3].addChild(leafsWords[2][4 * i + 3]);
                parents[3].addChild(leafsWords[3][4 * i + 2]);
                parents[3].addChild(leafsWords[3][4 * i + 3]);

                let parent = new ParentSegment(this.templateContainer.copyParentTemplate());
                parent.addChild(parents[0]);
                parent.addChild(parents[1]);
                parent.addChild(parents[2]);
                parent.addChild(parents[3]);

                rootSegment.addChild(parent);
            }
            this.setRootSegment(rootSegment)
        }
    }
}