import {MigrationInterface, QueryRunner, Table} from "typeorm";
import {MigrationHelper} from "js-helper/dist/shared";
import {LevelData} from "../../LevelData";

export class Setup1000000002000 implements MigrationInterface {

    async up(queryRunner: QueryRunner): Promise<any> {
        await MigrationHelper.addTableFromModelClass(LevelData, queryRunner);
    }

    down(queryRunner: QueryRunner): Promise<any> {
        return undefined;
    }
}